.scrolbar-none {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.scrolbar-none::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#preloader {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: #0f9015;
  background-size: 30%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  line-height: 1.2em;
  display: inline-grid;
  z-index: 9999999;
}

#preloader:before,
#preloader:after {
  content: "Loading...";
  grid-area: 1/1;
  -webkit-mask: linear-gradient(90deg, #000 50%, #0000 0) 0 50%/2ch 100%;
  color: #0000;
  text-shadow: 0 0 0 #000, 0 calc(var(--s, 1) * 1.2em) 0 #000;
  animation: l15 1s infinite;
}

#preloader:after {
  -webkit-mask-position: 1ch 50%;
  --s: -1;
}

#preloader img {
  position: absolute;
  width: 100px;
  top: 110px;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes l15 {
  80%,
  100% {
    text-shadow: 0 calc(var(--s, 1) * -1.2em) 0 #000, 0 0 0 #000;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: black;
  color: white;
  opacity: 75%;
  padding: 20px 80px;
  border-radius: 8px;
  text-align: center;
}

.popup-content button {
  padding: 10px 20px;
  margin-top: 10px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
